import { Injectable } from '@angular/core';
import { BaseService } from '../_util/base.service';
import { Session } from '../pages/authentication/model/session.model';
import { JwtData } from '../pages/authentication/dto/jwt-data.dto';
import { StorageKey } from '../_type/storage-key';
import { StorageService } from './storage.service';
import { NGXLogger } from 'ngx-logger';
import { zip, of, Subject, BehaviorSubject } from 'rxjs';
import { SessionError } from '../pages/authentication/error/session.error';
import * as jwt_decode from 'jwt-decode';
import { flatMap, share } from 'rxjs/internal/operators';
import { ApiResponse } from '../_type/api-response.type';
import { User } from '../pages/user/model/user.model';
import { Company } from '../pages/company/model/company.model';
import { Role } from '../pages/role/model/role.model';
import { SessionState } from '../_type/session-state.type';
import { ProfileService } from './profile.service';

@Injectable({ providedIn: 'root' })
export class SessionService extends BaseService {

  // tslint:disable-next-line: variable-name
  private _existsTokenStored: boolean;
  public get existsStoredToken() {
    return this._existsTokenStored;
  }
  // tslint:disable-next-line: variable-name
  private _currentUserIsLogged: boolean;
  public get currentUserIsLogged() {
    return this._currentUserIsLogged;
  }
  private session: Session;
  private stateSubject = new BehaviorSubject<SessionState | undefined>(undefined);
  public get onStateChange() {
    return this.stateSubject.asObservable();
  }
  public get currentState() {
    return this.stateSubject.value;
  }

  public userSubject = new BehaviorSubject<User | undefined>(undefined);
  public get onUserChange() {
    return this.userSubject.asObservable();
  }
  public get currentUser() {
    return this.userSubject.value;
  }

  constructor(
    logger: NGXLogger,
    private readonly storageService: StorageService,
    private readonly profileService: ProfileService,
  ) { super(logger); }

  checkStoredToken() {
    const storedToken = this.getStoredSessionToken();
    this._existsTokenStored = storedToken && typeof storedToken === 'string' && storedToken.length > 0;
    // TODO valid jwt token not expired
  }

  getStoredSessionToken(): string {
    return this.storageService.get(StorageKey.jwtToken, false) as string;
  }

  storeNewSessionToken(jwtToken: string) {
    this.storageService.set(StorageKey.jwtToken, jwtToken, false);
    this._existsTokenStored = true;
  }

  loadSession() {
    if (this._existsTokenStored) {
      const jwtData: JwtData = jwt_decode(this.getStoredSessionToken());
      // TODO verify user exists & user.access = jwt.access
      // TODO re-code using /profile end-point
      const observable = zip(
        this.profileService.getUser(),
        this.profileService.getCompany(),
        this.profileService.getRole(),
      ).pipe(
        flatMap(this.onLoadSessionDataSuccessful.bind(this)),
        share(),
      );

      observable.subscribe({
        complete: () => this.stateSubject.next(SessionState.loggedIn),
        error: this.onLoadSessionError.bind(this),
      });
      return observable;
    } else {
      throw new Error(SessionError.jwtNotFound);
    }
  }

  private onLoadSessionDataSuccessful([
    userResponse, companyResponse, roleResponse
  ]: [ApiResponse<User>, ApiResponse<Company>, ApiResponse<Role>]) {
    this.session = {
      user: userResponse.data,
      company: companyResponse.data,
      role: roleResponse.data,
    };
    this.userSubject.next(this.session.user);
    this._currentUserIsLogged = true;
    return of(true);
  }

  /*private onLoadSessionDataSuccessful() {
      this._currentUserIsLogged = true;
      return of(true);
  }*/

  private onLoadSessionError(error: Error) {
    this._error('SessionService', 'onLoadSessionError', error);

    this.finishSession();
  }

  getSession(
    property: keyof Session,
    field?: keyof User | keyof Company | keyof Role,
  ) {
    let _prop: User | Company | Role;
    if (this.session) {
      switch (property) {
        case 'user': {
          _prop = this.session.user;
          break;
        }
        case 'company': {
          _prop = this.session.company;
          break;
        }
        case 'role': {
          _prop = this.session.role;
          break;
        }
      }
    }
    if (_prop && field
      && _prop.hasOwnProperty(field)) {
      _prop = _prop[field];
    }
    return _prop;
  }

  // getUserSubject() {
  //     return this.userSubject;
  // }
  //
  // getUser() {
  //     return this.profileService.getUser();
  // }

  unloadSession() {
    delete (this.session);
    delete (this._currentUserIsLogged);
  }

  reloadSession() {
    this.unloadSession();
    return this.loadSession();
  }

  finishSession() {
    if (this._currentUserIsLogged) {
      this.unloadSession();
    }
    if (this._existsTokenStored) {
      delete (this._existsTokenStored);
      this.removeStoredSessionToken();
    }
    this.stateSubject.next(SessionState.loggedOut);
  }

  private removeStoredSessionToken() {
    this.storageService.remove(StorageKey.jwtToken);
  }

  updateSessionUser() {
    this.profileService.getUser().subscribe(dataApiResponseUser => {
      this.session.user = dataApiResponseUser.data;
    });
  }


}
