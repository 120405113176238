import { Component, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SidenavItem } from './sidenav-item/sidenav-item.interface';
import { SidenavService } from './sidenav.service';
import { ThemeService } from '../../../@fury/services/theme.service';
import { Company } from '../../pages/company/model/company.model';
import { User } from '../../pages/user/model/user.model';
import { SessionService } from '../../_service/session.service';
import { ProfileService } from '../../_service/profile.service';

@Component({
  selector: 'fury-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  company: Company;
  user: User;
  roleName: string;

  sidenavUserVisible$ = this.themeService.config$.pipe(map(config => config.sidenavUserVisible));

  @Input()
  @HostBinding('class.collapsed')
  collapsed: boolean;

  @Input()
  @HostBinding('class.expanded')
  expanded: boolean;

  items$: Observable<SidenavItem[]>;

  constructor(
    private readonly router: Router,
    private readonly sidenavService: SidenavService,
    private readonly themeService: ThemeService,
    private readonly sessionService: SessionService,
    private readonly profileService: ProfileService,
  ) { }

  ngOnInit() {
    this.user = this.sessionService.getSession('user');

    this.sessionService.onUserChange.subscribe(usr => {
      this.user = usr;
    });

    this.items$ = this.sidenavService.items$.pipe(
      map((items: SidenavItem[]) => this.sidenavService.sortRecursive(items, 'position'))
    );

    this.company = this.sessionService.getSession('company');

    this.roleName = this.sessionService.getSession('role').name;
  }

  toggleCollapsed() {
    this.sidenavService.toggleCollapsed();
  }

  @HostListener('mouseenter')
  @HostListener('touchenter')
  onMouseEnter() {
    this.sidenavService.setExpanded(true);
  }

  @HostListener('mouseleave')
  @HostListener('touchleave')
  onMouseLeave() {
    this.sidenavService.setExpanded(false);
  }

  onLogOut() {
    this.profileService.logOut().subscribe(() => {
      this.sessionService.finishSession();
      this.router.navigate(['/login']);
    });
  }

  goToEdit() {
    this.router.navigate(['app/edit-profile']);
  }

}
